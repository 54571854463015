'use strict';

import { webUtils } from "./web-utils";
import { analyticsIdentify } from './sunbasket.js';

function handleErrorDuringDirectJoin(internalErrorMsg) {
    console.error(internalErrorMsg);

    $('.copy-container').hide();
    $('.error-copy-container').show();
    $(window).scrollTop(0);
}

$('#easySetupForm, .sb-direct-join-form, .blue-cross-blue-shield-arizona-kitchen-join-form').on('submit', function (e) {
    e.preventDefault();

    let $form = $(this);
    let $lightVersion = !!$form.hasClass('light-version');

    if (!$form.parsley().isValid()) {
        return false;
    }

    let $submitButton = $('[type=submit]', $form);
    let cfg = webUtils.getSunbasketConfig();

    $submitButton.disableButton();

    webUtils.getCsrfToken(function (csrfResponse) {
        let data = {};

        if (!$lightVersion) {
            data.firstName = $form.find('input[name="firstName"]').val();
            data.lastName = $form.find('input[name="lastName"]').val();
            data.password = $form.find('input[name="password"]').val();
            data.confirmationPassword = $form.find('input[name="confirmationPassword"]').val();
        }

        data.email = $form.find('input[name="email"]').val();
        data.zip = $form.find('input[name="zip"]').val();

        let formTypeInput = $form.find('#formType');
        data.formType = formTypeInput.length ? formTypeInput.val() : 'default';

        let originInput = $form.find('#origin');
        data.origin = originInput.length ? originInput.val() : 'heroPromo';

        data.lightForm = $lightVersion;

        let promoCodeInput = $form.find('input[name="promoCode"]');
        let promoCodeValue = promoCodeInput.val();

        if (promoCodeInput) {
            if (promoCodeInput.data("singleUsePromoKey")) {
                data.singleUsePromoKey = promoCodeValue;
            } else {
                webUtils.setCookie('offer', promoCodeValue, false);
            }
        }

        let targetUrl = webUtils.getAjaxUrl('/cms/api/join');

        $.ajax({
            url: targetUrl,
            data: JSON.stringify(data),
            contentType: 'application/json',
            type: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                [csrfResponse.headerName]: csrfResponse.token,
            },
        }).done(function (response, status, jqXHR) {
            if (jqXHR.status !== 204 && jqXHR.status !== 200) {
                handleErrorDuringDirectJoin("Something went wrong while posting join form data to the CMS endpoint");
                return;
            }

            if (response && response.prospectId) {
                localStorage.setItem("prospectId", "\"" + response.prospectId + "\""); //double quotes must be a part of the string because React lib (https://usehooks-ts.com/react-hook/use-local-storage) is expecting this format
            }

            let targetPath = (response && response.redirectPath) || jqXHR.getResponseHeader('Location');
            if (!targetPath) {
                handleErrorDuringDirectJoin("Missing location header after posting join form data to the CMS endpoint");
                return;
            }

            window.location.href = webUtils.getAjaxUrl(targetPath);
        }).fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status !== 400) {
                handleErrorDuringDirectJoin("Something went wrong while posting join form data to the CMS endpoint");
            } else {
                jQuery.each(jqXHR.responseJSON, function (key, value) {
                    $('input[name="' + key + '"]', $form).parsley().addError('custom-error', {
                        message: value,
                        updateClass: true
                    });
                });

                $submitButton.enableButton();
            }
        });
    }, function (error) {
        handleErrorDuringDirectJoin("Unable to get CSRF token: " + error);
    });
});

$(function() {
    analyticsIdentify();

    $('.single-join-form').each(function() {
        const $singleJoinForm = $(this);
        let mealKitsSingleUrl = webUtils.getAjaxUrl('/join/mealkits');
        let freshReadySingleUrl = webUtils.getAjaxUrl('/join/fresh-ready');

        if ($singleJoinForm.hasClass('meal-kits-single')) {
            $singleJoinForm.attr('action', mealKitsSingleUrl);
        } else if ($singleJoinForm.hasClass('fresh-ready-single')) {
            $singleJoinForm.attr('action', freshReadySingleUrl);
        }

        $singleJoinForm.on('submit', function(e) {
            e.preventDefault();
            $('button[type=submit]', this).disableButton();

            let form = this;

            webUtils.getCsrfToken(function (csrfResponse) {
                $('#csrfToken', $singleJoinForm).val(csrfResponse.token);
                form.submit();
            });

            return false;
        });
    });
});
