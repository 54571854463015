'use strict';

// load webUtils lib

import {webUtils} from './web-utils.js';

var analyticsProperties = [
    "offer", "fbclid", "fcid", "fsid", "faid", "fssn", "fplc",
    "gclid", "gcid", "ggid", "gfid", "gcrid", "gnwk", "gdev",
    "gpos", "gmkw", "gkmt", "gtgt", "gdevm", "gplc", "gptg", "grnd", "gclid", "glci",
    "gloc", "gpar1", "gpar2", "gifm", "gidn", "itid", "icid", "ircd", "ichn", "imty",
    "idat", "ieml", "icnm", "iphy", "iumt", "iurl", "ibru", "ihuu", "sscid", "scid", "spid",
    "said", "stxn", "stty", "samt", "smid", "stid", "srnd", "mclk", "msclkid", "mrnd", "maid",
    "mcid", "mpid", "maty", "msid", "mpty", "msb1", "msb2", "msb3", "muts", "manm",
    "masz", "mpnm", "ocid", "oaid", "odid", "orid", "osrc", "olid", "octs", "ouid",
    "osec", "opid", "oclk", "oand", "oios", "opdt", "odtl", "odau", "oatl", "osnm",
    "opnm", "tdat", "tplt", "tcid", "titm", "tsnm", "tsid", "tttl", "timg", "bgid",
    "baid", "bbmt", "bcid", "bdev", "bfid", "btgt", "bmkw", "bkmt", "bclid", "bnwk",
    "boid", "bqst", "bcnm", "bgnm", "blci", "blcp", "utm_source", "utm_medium",
    "utm_campaign", "utm_term"
];

export function getQueryString() {
    var result = {}, queryString = location.search.slice(1),
        re = /([^&=]+)=([^&]*)/g, m;

    while (m = re.exec(queryString)) {
        result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }

    return result;
}

window.sunbasket = window.sunbasket || {};
window.sunbasket.cookies = {
    exists: function (name) {
        var r = new RegExp('(^|;) ?' + name + '=([^;]*)(;|$)');
        return r.test(document.cookie);
    },
    get: function (name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    },
    getAllWithPrefix: function (prefix) {
        let prefixedCookies = document.cookie.split(';').reduce((cookies, cookie) => {
            const [ name, value ] = cookie.split('=').map(c => c.trim());
            cookies[name] = value;
            return Object.fromEntries(Object.entries(cookies).filter(([name]) => name.startsWith(prefix)))
        }, {});
        return prefixedCookies;
    },
    getJson: function (name) {
        return JSON.parse(atob(window.sunbasket.cookies.get(name), true));
    },
    setJson: function (name, value, days) {
        return window.sunbasket.cookies.set(name, btoa(JSON.stringify(value), true), days);
    },
    set: function (name, value, days) {
        if (typeof days === 'undefined') {
            document.cookie = name + "=" + value + ";path=/";
        } else {
            var d = new Date;
            d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
            document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
        }

        return value;
    },
    delete: function (name) {
        window.sunbasket.cookies.set(name, '', -1);
    }
};

window.sunbasket.joinPreferences = {
    save: function (newPreferences) {
        var preferences = {};
        var queryString = window.sunbasket.joinPreferences.getFromQueryString();

        Object.keys(queryString).forEach(function (key) {
            preferences[key] = queryString[key];
        });

        if (newPreferences) {
            Object.keys(newPreferences).forEach(function (key) {
                var value = newPreferences[key];

                if (value.length > 0) {
                    if (key === 'mealPlan') {
                        value = window.sunbasket.joinPreferences.mapMealPlanChoice(value);
                    }

                    preferences[key] = value;
                }
            });
        }

        Object.keys(preferences).forEach(function (key) {
            window.sunbasket.cookies.set(key, preferences[key], 1);
        });
    },
    getFromQueryString: function () {
        var properties = ["mealPlan"];

        var query = getQueryString(), qs = {};
        for (var i = 0; i < properties.length; i++) {
            if (query.hasOwnProperty(properties[i])) {
                qs[properties[i]] = query[properties[i]];
            }
        }

        return qs;
    },
    mapMealPlanChoice: function (mealPlanChoice) {
        var map = {
            'meal-plans-classic-chefschoice': 'CHEFS_CHOICE',
            'meal-plans-classic-paleo': 'PALEO',
            'meal-plans-classic-carb-conscious': 'CARB_CONSCIOUS',
            'meal-plans-classic-leanandclean': 'LEAN_AND_CLEAN',
            'meal-plans-classic-glutenfree': 'GLUTEN_FREE',
            'meal-plans-classic-vegetarian': 'VEGETARIAN',
            'meal-plans-classic-mediterranean': 'MEDITERRANEAN',
            'meal-plans-classic-vegan': 'VEGAN',
            'meal-plans-classic-pescatarian': 'PESCATARIAN',
            'meal-plans-classic-diabetes-friendly': 'DIABETES_FRIENDLY',
            'meal-plans-classic-quick-and-easy': 'QUICK_AND_EASY',
            'meal-plans-family-chefschoice': 'CHEFS_CHOICE_FP',
            'meal-plans-family-paleo': 'PALEO_FP',
            'meal-plans-family-glutenfree': 'GLUTEN_FREE_FP',
            'meal-plans-family-vegetarian': 'VEGETARIAN_FP'
        };

        return map[mealPlanChoice] || mealPlanChoice;
    }
};

window.sunbasket.analytics = {
    updateAttributionCookie: function (identifyIfChanged) {
        var parameters = {};

        try {
            parameters = window.sunbasket.cookies.getJson('cdpAttributionParameters');

            if (parameters instanceof Array || !(parameters instanceof Object)) {
                parameters = {};
            }
        } catch (ignored) {
        }

        var querystring = window.sunbasket.analytics.querystring();

        Object.keys(querystring).forEach(function (key) {
            parameters[key] = querystring[key];
        });

        if (Object.keys(parameters).length > 0) {
            var previousChecksum = '';

            if (identifyIfChanged) {
                previousChecksum = '' + window.sunbasket.cookies.get('cdpAttributionParameters');
            }

            var currentChecksum = window.sunbasket.cookies.setJson('cdpAttributionParameters', parameters);

            if (identifyIfChanged && window.analytics && previousChecksum !== currentChecksum) {
                window.analytics.identify(parameters);
            }
        }
    },
    once: function (id, callee) {
        if (typeof window.analytics !== 'undefined') {
            if (window.sunbasket.cookies.exists('sunbasket_analytics_' + id)) {
                return;
            }

            callee(window.analytics);

            window.sunbasket.cookies.set('sunbasket_analytics_' + id, 'yes');
        }
    },
    onceIgnoreNoAnalytics: function (id, callee) {
        if (window.sunbasket.cookies.exists('sunbasket_analytics_' + id)) {
            return;
        }

        callee(window.analytics);

        window.sunbasket.cookies.set('sunbasket_analytics_' + id, 'yes');
    },
    reset: function (id) {
        window.sunbasket.cookies.delete('sunbasket_analytics_' + id);
    },
    querystring: function () {
        var query = getQueryString(), qs = {};
        for (var i = 0; i < analyticsProperties.length; i++) {
            if (query.hasOwnProperty(analyticsProperties[i])) {
                qs[analyticsProperties[i]] = query[analyticsProperties[i]];
            }
        }

        return qs;
    },
    initTestFlowId: function () {
        var cookieName = 'test_flow_id';

        if (!webUtils.hasCookie(cookieName)) {
            //Test Flow ID is a random number between 0 and 64 (inclusive)
            webUtils.setCookie(cookieName, Math.floor(Math.random() * 65), 365);
        }
    },
};

//load script within other function

jQuery.loadScript = function (url, callback) {
    jQuery.ajax({
        url: url,
        dataType: 'script',
        success: callback,
        async: true
    });
}

function selectNavTab() {
    var currentURL = window.location.pathname.split('/');

    currentURL = currentURL.filter(function (el) {
        return el != '';
    });
    var pageName = currentURL[currentURL.length - 1];

    // for blog post pages who's url isn't so simple...
    if (currentURL.indexOf('menu') !== -1) {
        pageName = 'menu-link';
    }
    if (currentURL.indexOf('farms') !== -1) {
        pageName = 'farm-link';
    }
    if (currentURL.indexOf('values') !== -1) {
        pageName = 'values-link';
    }
    if (currentURL.indexOf('how-it-works') !== -1) {
        pageName = 'learn-link';
    }
    if (currentURL.indexOf('pricing') !== -1) {
        pageName = 'learn-link';
    }
    // remove googleTagId if exists
    if (pageName === 'mymenu' ||
        pageName === 'refer-a-friend' ||
        pageName === 'zine' ||
        pageName === 'learn-link' ||
        pageName === 'values-link' ||
        pageName === 'farm-link' ||
        pageName === 'menu-link') {
        $('.nav-tab').removeClass('active');
        $('#' + pageName + ', .' + pageName).addClass('active');
    }
}

function indicateNavTabSelected() {
    var url = window.location.pathname.split('/').filter(Boolean)[0];

    $('.blog-subnav .nav-tab').each(function () {
        if ($(this).attr('data-nav-tab') == url) {
            $(this).parent().find('.indicator').addClass('active');
        }
    });
}

function addIDToTrySections() {
    var used = [];
    $('.try section').each(function () {
        if ($(this).attr('id') === undefined) {
            var id = $(this).attr('class').split(' ')[0];
            var count = 0;
            used = checkInArray(used, id, count);
            $(this).attr('id', used[used.length - 1]);
        }
    });
}

function checkInArray(array, id, count) {
    if (count < 10) {
        if (array.indexOf(id) !== -1) {
            var split = id.split('-');
            if (!split.length > 1) {
                id = `${id}-1`;
            } else {
                var num = parseInt(split[split.length - 1]) + 1;
                if (num > 10) return array;
                id = `${split[0]}-${num}`;
                count += 1;
                checkInArray(array, id, count);
            }

        }
    }
    array.push(id);
    return array;
}

function openYoutubeModal() {
    $('.youtube-button').on('click', function (e) {
        var ytId = $(this).attr('data-youtube-id');
        var src = $(this).attr('data-src');
        var width = $(this).attr('data-width') || 640;
        var height = $(this).attr('data-height') || 360;
        var allowfullscreen = $(this).attr('data-video-fullscreen');
        var videoTitle = $(this).parent().parent().find('.recipe-name').text().trim();

        $('#youtube-modal-' + ytId + ' iframe').attr({
            'src': src,
            'height': height,
            'width': width,
            'allowfullscreen': ''
        });
    });
    $('.youtube-modal').on('hidden.bs.modal', function () {
        $(this).find('iframe').html('');
        $(this).find('iframe').attr('src', '');
        $('.modal-backdrop').remove();
    });
    $('.close, .modal-content').on('click', function (e) {
        $('.youtube-modal').trigger('click');
    });
}

function updateScrollArrows($this) {
    var $recipeTile = $this.find('[class*="tile-container"]');

    if ($(window).width() > 767) {
        var $width = $this.outerWidth();
        var $scrollWidth = $this.prop('scrollWidth');
        var $scrollLeft = $this.scrollLeft();
        var $scrollContainer = $this.parents('.horizontal-scroll-container');

        if ($scrollLeft === ($scrollWidth - $width)) {
            $scrollContainer.find('.right-shadow').hide();
            $scrollContainer.find('.right-arrow').hide();
        } else if ($scrollWidth > $width) {
            $scrollContainer.find('.right-shadow').show();
            $scrollContainer.find('.right-arrow').show();
        }

        if ($scrollLeft === 0) {
            $scrollContainer.find('.left-shadow').hide();
            $scrollContainer.find('.left-arrow').hide();
        } else if ($scrollWidth > $width) {
            $scrollContainer.find('.left-shadow').show();
            $scrollContainer.find('.left-arrow').show();
        }

        $recipeTile.css('width', '40%');

        if ($(window).width() > 992) {
            $recipeTile.css('width', '31%');
        }

        if ($(window).width() > 1200) {
            $recipeTile.css('width', '28%');
        }
    } else {
        $recipeTile.css('width', '100%');
    }
}

function submitNewsletterForm() {
    let $form = $('#footer_lead_capture_form, #newsletter_lead_capture_form');

    $form.submit(function (e) {
        e.preventDefault();

        let $submitButton = $('[type=submit]', $form);
        $submitButton.disableButton();

        let emailAddress = $('#footer_prospect_email, #newsletter_prospect_email').val();

        webUtils.postToLeadCapture(emailAddress, 353058, function (response) {
            if (response.status === 200) {
                $form.find('.success-msg').fadeIn('slow').delay(10000).fadeOut('slow');
                $form.find('.newsletter-input').val('');
                $submitButton.enableButton();
            } else {
                $form.find('.error-msg').fadeIn('slow').delay(10000).fadeOut('slow');
                $submitButton.enableButton();
            }
        });
    });
}

function adjustYearAndVersionInFooter() {
    var date = new Date();
    var $footer_text = $('footer .legal-text');
    $footer_text.find('.year').text(date.getFullYear());

    $.get(webUtils.getAjaxUrl('/cms/api/version')).done(function (json) {
        $footer_text.find('.version').text(json);
    });
}

function setupCarousels() {
    $('#vendor-carousel').carousel({
        interval: false
    });

    $('#supplier-carousel').carousel({
        interval: false
    });

    $('#welcome-carousel').carousel({
        interval: false,
    });

    $('#bootstrap-carousel').carousel({
        interval: 3000,
        wrap: true
    });
}

function addActiveClassToCurrentNavItem() {
    var path = window.location.pathname;

    $('body.logged-out .navbar-header a.nav-tab').each(function () {
        if (path.includes($(this)[0].pathname)) {
            $(this).addClass('active');
        }
    });
}

//set cookies based on meal plan button click for Explore section

function cookieToPopulateJoinFlow() {
    $('.meal-plans-tile-btn, .mobile-meal-plans-tile-btn').click(function (e) {
        window.sunbasket.joinPreferences.save({mealPlan: this.id});
        webUtils.deleteCookie('noPrepPreferred');
    });

    if ($('body.single-meal_plans').length > 0) {
        var data = $('.hero-landing.data-store').attr('data-id');

        $('.btn-primary').click(function () {
            window.sunbasket.joinPreferences.save({mealPlan: data});
            webUtils.deleteCookie('noPrepPreferred');
        });
    }

    if ($('body.single-try').length > 0) {
        $('.offer-btn').click(function () {
            var data = $('.hero.data-store').attr('data-id');
            window.sunbasket.joinPreferences.save({mealPlan: data});
            webUtils.deleteCookie('noPrepPreferred');
        });
    }
}

function generateAnonymousId() {
    var seed = Date.now();
    if (window.performance && typeof window.performance.now === "function") {
        seed += performance.now();
    }

    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (seed + Math.random() * 16) % 16 | 0;
        seed = Math.floor(seed / 16);

        return (c === 'x' ? r : r & (0x3 | 0x8)).toString(16);
    });

    return uuid;
};

function swapPodcastWelcomeMessage() {
    if (webUtils.isMobile() && $('#offer-modal').hasClass('show-on-mobile') != true) {
        $('#offer-modal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
    } else {
        if ($('#offer-modal').length > 0) {
            $('#offer-modal').modal('show');
        }
    }
}

function autoPlayYouTubeModal() {
    var trigger = $("body").find('[data-toggle="modal"]');
    trigger.click(function () {
        var theModal = $(this).data("target"),
            videoSRC = $(this).attr("data-theVideo"),
            videoSRCauto = videoSRC + "?&amp;autoplay=1;rel=0;";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal + ' span.close').click(function () {
            $(theModal + ' iframe').attr('src', videoSRC);
        });
    });
}

function socialSharing() {
    $(".social-share-fb").attr("href", "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(location.protocol + '//' + location.host + location.pathname + "?offer=${user.referralCode}"));
    $(".twitter-share-button").attr("href", "https://twitter.com/intent/tweet?via=sunbasket&text=" + encodeURIComponent(document.title) + "&url=" + encodeURIComponent(location.protocol + '//' + location.host + location.pathname));
}

export function analyticsIdentify() {
    function aliasItselfInternal() {
        var analyticsAnonymousId = window.analytics && typeof window.analytics.user == 'function' ? window.analytics.user().anonymousId() : generateAnonymousId();

        if (window.analytics) {
            var cdpAttributionParameters = {};

            try {
                cdpAttributionParameters = window.sunbasket.cookies.getJson('cdpAttributionParameters');

                if (cdpAttributionParameters instanceof Array || !(cdpAttributionParameters instanceof Object)) {
                    cdpAttributionParameters = {};
                }
            } catch (ignored) {
            }

            cdpAttributionParameters['test_flow_id'] = webUtils.getCookie('test_flow_id') || '';

            window.analytics.alias(analyticsAnonymousId);
            window.analytics.identify(analyticsAnonymousId, cdpAttributionParameters);
        }
        window.sunbasket.cookies.set('analyticsAnonymousId', analyticsAnonymousId);
    }

    window.sunbasket.analytics.onceIgnoreNoAnalytics('alias_itself', function () {
        if (window.analytics) {
            window.analytics.ready(function() {
                aliasItselfInternal();
            });
        } else {
            aliasItselfInternal();
        }
    });
}

$(document).ready(function () {
    $.fn.disableButton = function (showLoader = true) {
        if (showLoader) {
            $('.button-loader', this).show();
        } else {
            $('.button-loader', this).hide();
        }

        return this.prop('disabled', true).addClass('disabled');
    };

    $.fn.enableButton = function () {
        $('.button-loader', this).hide();

        return this.prop('disabled', false).removeClass('disabled');
    };

    window.sunbasket.analytics.initTestFlowId();
    window.sunbasket.analytics.updateAttributionCookie(true);
    window.sunbasket.joinPreferences.save();

    var pathArray = window.location.pathname.toLowerCase().split('/');

    selectNavTab();
    indicateNavTabSelected();
    openYoutubeModal();
    socialSharing();

    $('.navbar-collapse .navbar-nav li a').click(function () {
        $('.navbar-collapse').toggleClass('in');
    });

    $('.page-wrapper').click(function () {
        if ($('.navbar-collapse.in').length > 0) {
            $('.navbar-collapse').toggleClass('in');
        }
    });

    // Tooltip functionality
    $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover',
        container: 'body',
        delay: 2,
    });

    if (webUtils.isMobile()) {
        $('[data-toggle="tooltip"]').on('shown.bs.tooltip', function () {
            $('body').css('cursor', 'pointer');
        });

        $('[data-toggle="tooltip"]').on('hide.bs.tooltip', function () {
            $('body').css('cursor', 'auto');
        });
    }

    $('.btn-bar-toggle').each(function () {
        var group = $(this);
        $('button', group).each(function () {
            $(this).click(function () {
                $(this).parents('.btn-bar-toggle').find('.btn').removeClass('active');
                $(this).addClass('active');
                var btnBarId = $(this).index();
                $('.btn-bar-toggle-view').hide();
                $('.btn-bar-toggle-view').eq(btnBarId).show();
            });
        });
    });

    setupCarousels();

    cookieToPopulateJoinFlow();

    addIDToTrySections();

    submitNewsletterForm();

    adjustYearAndVersionInFooter();

    swapPodcastWelcomeMessage();

    webUtils.dynamicHeroBackgroundImage();
    webUtils.dynamicHeroBackgroundImageNew();

    autoPlayYouTubeModal();

    addActiveClassToCurrentNavItem();

    $(window).resize(function () {
        webUtils.dynamicHeroBackgroundImage();
        webUtils.dynamicHeroBackgroundImageNew();
    });

    // unhandledrejection
    window.addEventListener('unhandledrejection', function (event) {
        console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
    });

    // window.addEventListener('unhandledrejection', function (evt) {
    //   Raven.captureException(evt.reason)
    // })

});
